import { useSuspenseQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GozioTable from 'components/tables/gozioTable';
import { convertDateToString } from 'helpers/date-util';
import { capitalize, getLabelByLang, SUPPORTED_LANGUAGE_CODES } from 'helpers/lang-util';
import { getTableCountTitle } from 'helpers/table-util';
import useHandleError from 'hooks/useHandleError';
import React, { useMemo } from 'react';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import { GET_NETWORK_NOTIFICATIONS } from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: 0,
  },
}));

const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Date Sent',
    accessor: 'createdAt',
    Cell: ({ cell }) => convertDateToString(cell.value),
    minWidth: 80,
    width: 80,
    sticky: 'left',
  },
  {
    Header: 'Message Title English',
    accessor: 'titleEn',
  },
  {
    Header: 'Visibility Rule',
    accessor: 'visibilityRule',
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'Geolocation',
    accessor: 'geolocation',
    minWidth: 160,
    width: 160,
  },
];

const History = (props) => {
  const classes = useStyles();
  const { handleError } = useHandleError('PushNotifications');
  const isUberAdmin = useCheckGozioAdmin();
  const languages = useActiveNetworkLanguages();

  const memoizedColumns = useMemo(() => {
    const c = [...COLUMNS];
    if (isUberAdmin) c.push({
      Header: 'App Version',
      accessor: 'mobileAppName',
      minWidth: 80,
      width: 80,
    });

    if (languages.length > 1) {
      c.splice(3, 0, {
        Header: 'Message Title Spanish',
        accessor: 'titleEs',
        minWidth: 80,
        width: 80,
      });
    }

    return c;
  }, [isUberAdmin, languages]);


  const { data } = useSuspenseQuery(GET_NETWORK_NOTIFICATIONS, {
    context: { headers: { network: props.networkId } },
    variables: { networkId: props.networkId },
    fetchPolicy: 'network-only',
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  const processHistory = () => data?.getNetwork?.notifications?.map((notification) => ({
      id: notification.id,
      titleEn: getLabelByLang(notification.title),
      titleEs: getLabelByLang(notification.title, SUPPORTED_LANGUAGE_CODES.SPANISH),
      createdAt: notification.createdAt,
      type: notification.type,
      visibilityRule: notification.visibilityRuleCustom ? 'Custom' : capitalize(notification.visibilityRule?.name) || 'All Users',
      geolocation: notification.geofence
        ? `Include all users within ${Math.floor(notification.geofence.radius / 1609)} mile radius of latitude ${notification.geofence.latitude} and longitude ${notification.geofence.longitude}`
        : 'No',
      mobileAppName: notification.mobileApp?.name,
    }));

  const memoizedData = useMemo(
    () => processHistory(),
    [data], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const count = memoizedData?.length || 0;

  return (
    <Grid item className={classes.root}>
      <GozioTable
        name="NotificationsTable"
        columns={memoizedColumns}
        data={memoizedData}
        sortBy={[{ id: 'createdAt', desc: true }]}
        sx={{ paddingTop: '8px' }}
        countTitle={getTableCountTitle(count, 'Notification')}
      />
    </Grid>
  );
};

export default History;
